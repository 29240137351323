// theme colors
#root {
  --primary: #653973;
  --secondary: #D1B3FA;
  --border: #EAD2F3;
  --background: #F1EDF7;
  --text: #4D4D4D;
  --text_2: #0A3250;
}

$primary: var(--primary);
$secondary: var(--secondary);
$border: var(--border);
$background: var(--background);
$text: var(--text);
$text-2: var(--text_2);

$link: #59BAC9;
$link_disabled: #979797;

$white: #FFFFFF;
$white-2: #FCFCFC;
$black: #20242D;
$grey-line: #F2F2F2;
$grey-line-2: #D1D1D1;
$grey-line-3: #E7E7E7;
$grey-line-4: #DFDFDF;
$grey: #ADADAD;
$grey-2: #C5E9EF;
$grey-3: #D2ECF0;
$grey-4: #AEAEAE;
$grey-bg: #F5F5F5;
$blue: #54B9D8;
$green: #8EDD50;
$red: #EC5C53;