@import './assets/scss/general/mixins';
@import './assets/scss/framework/scss-variables';

@import './assets/scss/reset';

@import './assets/scss/typography';

@import 'animate.css';

.iks-wrap {
  max-width: $wrap;
  margin: 0 auto;

  @include media(">tiny") {
    padding: 0 $gapDesktop;
  }

  @include media("<=tiny") {
    padding: 0 $gapMobile;
  }
}

.iks-reset-text-styles {
  * {
    font-family: inherit !important;
    color: inherit !important;
  }
}

.iks-main {
  position: relative;
  min-height: 100vh;
  min-width: $extratiny;
  background: linear-gradient(180deg, #FFFFFF 0%, #fCfCfC 30%, #FDFDFD 100%);

  &-bg {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('./assets/img/bg-main.png');
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    filter: grayscale(100%);

    @include media('<1580px') {
      background-size: 1580px auto;
    }
  }

  & > * {
    position: relative;
  }
}

.iks-bubble {
  position: relative;
  background-color: #FFF;
  border-radius: 6px;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    left: 20px;
    border-style: solid;
    border-color: transparent #FFF;
    border-width: 40px 50px 0px 0px;
    z-index: -1;
  }
}